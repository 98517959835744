<template>
  <MainLayout>
    <template v-slot:navbar-title>
      Добавить
    </template>
    <SlideLayout :tabs="slideTabs">
      <template v-slot:manual_input>
        <CreateFormLayout>
          <template v-slot:title__text>
            Сотрудник

            <v-radio-group
              class="ml-7 v-input--radio-role__input "
              row
              v-model="role"
            >
              <v-radio
                v-for="(avRole, index) in availableRoles"
                :key="index"
                :value="avRole.role"
                :label="avRole.text"
              >
              </v-radio>
            </v-radio-group>
          </template>

          <template v-slot:title-flex-right>
            <div></div>
          </template>

          <template>
            <div>
              <FormCardTitle title="Личные данные"></FormCardTitle>
              <FormInputBlock>
                <ValidationInputField
                  label="Телефон"
                  v-model="phone_number"
                  mask="+7 (###) ###-##-##"
                  rules="required|all_phone"
                  validate-name="Телефон"
                />
                <ValidationInputField
                  label="Пользователь (необязательно)"
                  validate-name="пользователь"
                  :rules="'min:1|max:40'"
                  v-model="user"
                  :disabled="!disabledEdit"
                />
                <!--            {{currentUser[0].additional_fields[0].value}}-->
                <ValidationInputField
                  label="Комментарий (необязательно)"
                  validate-name="комментарий"
                  :rules="'min:1|max:40'"
                  v-model="comments"
                  :disabled="!disabledEdit"
                />
              </FormInputBlock>
            </div>
            <!--    Данные компании    -->
            <div v-if="role === 20 && currentRole != 60">
              <FormCardTitle>
                <p class="data__text">Добавить детские группы</p>
              </FormCardTitle>
              <FormInputBlock>
                <ValidationAutocompleteField
                  :search-function="userGroup"
                  v-model="group"
                  label="Номер группы"
                />
              </FormInputBlock>
            </div>
          </template>

          <template v-slot:btns="{ handleSubmit }">
            <a @click="$router.go(-1)" class="btn btn_color_white">
              Отмена
            </a>
            <v-btn
              type="submit"
              @click="handleSubmit(createUsers)"
              class="btn btn-green"
            >
              Сохранить
            </v-btn>
          </template>
        </CreateFormLayout>
      </template>
      <template v-slot:add_fail>
        <CreateFormLayout>
          <template v-slot:title__text>
            Массовое добавление сотрудников
            <v-radio-group class="ml-7" row v-model="role">
              <v-radio
                v-for="(avRole, index) in availableAddAllRoles"
                :key="index"
                :value="avRole.role"
                :label="avRole.text"
              >
              </v-radio>
            </v-radio-group>
          </template>

          <template v-slot:title-flex-right>
            <div></div>
          </template>

          <div class="instruction">
            <p class="instruction__title">Правила заполнения файла:</p>
            <div class="instruction__description">
              <ol>
                <li>
                  Каждая строка должна содержать номер телефона и поля с
                  дополнительной информацией: пользователь, комментарий. Всего 3
                  поля.
                </li>
                <li>
                  Номер телефона должен соответствовать формату 79876543210. Не
                  должно быть пробелов, скобок и других знаков.
                </li>
                <li>
                  Поля с дополнительной информацией являются текстовыми.
                  Максимальная длина 40 символов.
                </li>
                <li>Не допускаются пустые строки.</li>
                <li>
                  Пример заполнения
                  <a href="/img/images/documentations/upload_users.png"
                    >по ссылке.</a
                  >
                </li>
              </ol>
            </div>
          </div>

          <template>
            <!--    Данные компании    -->
            <div>
              <FormInputBlock>
                <ValidationFileField
                  v-model="file"
                  :rules="'required'"
                  accept=".xlsx"
                  label="Выбрать файл"
                  validate-name="Выбрать файл"
                />
                <ValidationAutocompleteField
                  style="display: none"
                  :search-function="groupUserSearch"
                  label="Группа пользователей"
                  v-model="group_file"
                />
              </FormInputBlock>
            </div>
            <!--    Данные компании    -->
          </template>
          <template v-slot:btns="{ handleSubmit }">
            <a @click="$router.go(-1)" class="btn btn_color_white">
              Отмена
            </a>
            <v-btn
              type="submit"
              @click="handleSubmit(uploadPersonel)"
              class="btn btn-green"
            >
              Сохранить
            </v-btn>
          </template>
        </CreateFormLayout>
      </template>
    </SlideLayout>
    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormCardTitle from "@/components/createFormBlocks/FormCardTitle";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";
// import phoneFilter from "@/helpers/filters/phoneFilter";
import SlideLayout from "@/components/MoreInfo/SlideLayout";

import { getChildrenGroupsRequest } from "@/helpers/api/childrenGroup";
import { getAccessGroupsRequest } from "@/helpers/api/access-group";
// import { getUsersClearRequest } from "../../helpers/api/user";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
import ValidationFileField from "@/components/ValidationField/ValidationFileField.vue";

export default {
  name: "personnelCreatePage",
  components: {
    ValidationFileField,
    ValidationAutocompleteField,
    ValidationInputField,
    FormInputBlock,
    FormCardTitle,
    CreateFormLayout,
    MainLayout,
    SlideLayout
  },
  data() {
    return {
      loading: false,
      number: null,
      groupNumber: null,
      currentUser: null,
      currentRoles: null,
      disabledEdit: false,
      slideTabs: [
        {
          text: "Добавить вручную",
          value: "manual_input",
          icon: null,
          count: null
        },
        {
          text: "Загрузить Файл XLSX",
          value: "add_fail",
          icon: null,
          count: null
        }
      ],
      availableRoles: [
        {
          text: "Администратор",
          role: 30
        },

        {
          text: "Другой персонал",
          role: 80
        },
        {
          text: "Воспитатель",
          role: 20
        }
      ],
      availableAddAllRoles: [
        {
          text: "Другой персонал",
          role: 80
        }
      ],
      groupUserSearch: async value => {
        const id = this.$store.getters.getCurrentOrganization;
        const OrgId = this.$store.getters.getKindergartenId;
        return (
          await getAccessGroupsRequest({
            query: { ids: value, organization: OrgId || id, role: 120 }
          })
        ).data.results.map(el => ({
          text: el.title,
          value: el.id
        }));
      },
      // Users: async value => {
      //   return (
      //     await getUsersClearRequest({
      //       query: {
      //         search: value
      //       }
      //     })
      //   ).data.results.map(el => ({
      //     text:
      //       this.$store.getters.getCurrentRole != 31
      //         ? el.last_name +
      //           " " +
      //           el.first_name +
      //           " " +
      //           el.middle_name +
      //           phoneFilter.execute(el.phone_number)
      //         : phoneFilter.execute(el.phone_number),
      //     value: el.id
      //   }));
      // },

      userGroup: async value => {
        const id = this.$store.getters.getCurrentOrganization;
        // const OrgId = this.$store.getters.getKindergartenId;
        return (
          await getChildrenGroupsRequest({
            query: {
              ids: value,
              kindergarten_org: this.kindergartenId || id
            }
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id
        }));
      }
    };
  },
  props: {
    kindergartenId: {
      type: [Number, String]
    }
  },
  created() {
    this.role = 80;
    this.groupUserSearch("").then(res => {
      this.userAccessGroup = res[0]?.value;
    });
  },
  watch: {
    phone_number: async function(val) {
      let data = {
        phone_number: this.phone_number
      };
      if (val?.length == 18) {
        this.$store.dispatch("fetchKindergartenUserInfo", { data }).then(() => {
          this.currentUser = this.$store.getters.getCreateCurrentUserKindergartens;
          this.dadataField();
        });
      }
    }
  },
  computed: {
    currentRole() {
      return this.$store.getters.getCurrentRole;
    },
    currentKindergartenId() {
      return this.kindergartenId || this.$store.getters.getCurrentOrganization;
    },

    userAccessGroup: {
      get() {
        return this.$store.getters.getUserGroup;
      },
      set(newValue) {
        this.$store.commit("setUsersList", {
          listName: "userGroup",
          users: newValue
        });
      }
    },
    // users: {
    //   get() {
    //     return this.$store.getters.getUsers;
    //   },
    //   set(newValue) {
    //     this.$store.commit("setUsersList", {
    //       listName: "users",
    //       users: newValue
    //     });
    //   }
    // },
    group: {
      get() {
        return this.$store.getters.getPersonnelCreate.teacherGroup;
      },
      set(newValue) {
        this.groupNumber = newValue;
        this.$store.commit("setPersonnelCreate", {
          fieldName: "teacherGroup",
          value: newValue
        });
      }
    },
    role: {
      get() {
        return this.$store.getters.getPersonnelCreate.selectedRole;
      },
      set(newValue) {
        this.currentRoles = newValue;
        this.$store.commit("setPersonnelCreate", {
          fieldName: "selectedRole",
          value: newValue
        });
      }
    },
    user: {
      get() {
        return this.$store.getters.getPersonnelCreate.user;
      },
      set(newValue) {
        this.$store.commit("setPersonnelCreate", {
          fieldName: "user",
          value: newValue
        });
      }
    },
    group_file: {
      get() {
        return this.$store.getters.getCreateUserFileForm.group;
      },
      set(newValue) {
        this.$store.commit("setCreateUserFileForm", {
          fieldName: "group",
          value: newValue
        });
      }
    },
    file: {
      get() {
        return this.$store.getters.getCreateUserFileForm.file;
      },
      set(newValue) {
        this.$store.commit("setCreateUserFileForm", {
          fieldName: "file",
          value: newValue
        });
      }
    },
    comments: {
      get() {
        return this.$store.getters.getPersonnelCreate.comments;
      },
      set(newValue) {
        this.$store.commit("setPersonnelCreate", {
          fieldName: "comments",
          value: newValue
        });
      }
    },
    phone_number: {
      get() {
        return this.$store.getters.getPersonnelCreate.phone_number;
      },
      set(newValue) {
        this.number = newValue;
        this.$store.commit("setPersonnelCreate", {
          fieldName: "phone_number",
          value: newValue
        });
      }
    }
    // lastName: {
    //   get() {
    //     return this.$store.getters.getPersonnelCreate.last_name;
    //   },
    //   set(newValue) {
    //     this.$store.commit("setPersonnelCreate", {
    //       fieldName: "last_name",
    //       value: newValue
    //     });
    //   }
    // },
    // middleName: {
    //   get() {
    //     return this.$store.getters.getPersonnelCreate.middle_name;
    //   },
    //   set(newValue) {
    //     this.$store.commit("setPersonnelCreate", {
    //       fieldName: "middle_name",
    //       value: newValue
    //     });
    //   }
    // },
    // name: {
    //   get() {
    //     return this.$store.getters.getPersonnelCreate.first_name;
    //   },
    //   set(newValue) {
    //     this.$store.commit("setPersonnelCreate", {
    //       fieldName: "first_name",
    //       value: newValue
    //     });
    //   }
    // }
  },
  beforeDestroy() {
    this.$store.commit("clearPersonnelForm");
    this.$store.commit("clearUserPersonnel");
  },
  methods: {
    uploadPersonel() {
      this.loading = true;
      this.$store
        .dispatch("uploadUsers")
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.upload(successMessageDictionary.personnels)
          );
        })
        .finally(() => (this.loading = false));
    },
    dadataField() {
      if (this.currentUser.length == 0) {
        this.$store.commit("clearPersonnelForm");
        this.phone_number = this.number;
        this.group = this.groupNumber;
        this.role = this.currentRoles;
        this.disabledEdit = true;
      } else {
        this.disabledEdit = false;
        this.user = this.currentUser[0].additional_fields[0].value;
        this.comments = this.currentUser[0].additional_fields[1].value;
      }
    },
    createUsers() {
      this.$store
        .dispatch("vsCreateStuff", this.currentKindergartenId) // добавляем пользователя в группы
        .then(userId => {
          // добавляем воспитателя в детскую группу

          if (this.group) {
            return new Promise(resolve =>
              setTimeout(
                resolve(
                  this.$store.dispatch("addTeacherToGroup", {
                    groupId: this.group,
                    teacherId: userId.data.id
                  })
                ),
                0
              )
            ); // setTimeout потому что приходит ошибка что пользователь еще не добавлен в группу воспитатель, поэтому исскуственно делаем задержку
          }
          return;
        })
        .then(() => {
          this.loading = true;
          this.$router.go(-1);
          this.$store.commit("clearPersonnelForm");
          this.$store.commit("clearUserPersonnel", {
            fieldName: "userGroup"
          });
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.add(successMessageDictionary.personnel)
          );
        })
        .catch(error => {
          const detailMessage = error.response?.data?.detail;
          const errorMessages =
            error.response?.data?.errors?.map(err => err.message) || [];
          const allErrors = detailMessage
            ? [detailMessage, ...errorMessages]
            : errorMessages;
          this.$store.commit("SET_ERROR_NOTIFICATION", {
            errors: allErrors.map(message => ({ message }))
          });
        })

        .finally(() => {
          this.loading = false;
        });
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("clearPersonnelForm");
    this.$store.commit("clearCreateUserForm");
    next();
  }
};
</script>

<style scoped></style>
